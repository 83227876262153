#user-profile {
  .profile-with-cover {
    .profil-cover-details {
        margin-top: -50px;
        .profile-image {
          img.img-border{
            border: 5px solid #fff;
          }
        }
    }
  }
}
.profile-section {
  .profile-menu {
    position: relative;
    top: -30px;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-around;
    li {
      a {
        display: block;
      }
    }
  }
}