.navbar {
  z-index: 1; // required to make the shadow overlap the below content (need is visible in mobile drawer overlay)
  border: 0;
  border-radius: 0;

  .navbar-brand {
    position: relative;
  }

  .navbar-nav{
    display: block;
    margin-right: -18px;
    .dropdown-menu{
      position: absolute;
    }
  }

  .navbar-toggle{
    background-color: transparent;
    border: none;
    .icon-bar{
      background-color: inherit;
      border: 1px solid;

      & + .icon-bar{
        margin-top: 4px;
      }
    }
  }

  .notification{
    position: absolute;
    top:-1px;
    right:13px;
  }
}
